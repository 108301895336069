import React, { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import { useTheme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import CustomPagination from "../../common/CustomPagination";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import TableSearchField from "../../common/textfields/TableSearchField";
import { SchedulePaymentModel } from "../../../model/accountManagementPaymentModel";

const headerLabels: string[] = ["Installment", "Due Date", "Amount", "Status"];

type Props = {
  handleToggleUpload: () => void;
  schedules: SchedulePaymentModel[];
};

const PaymentSchedule = ({ handleToggleUpload, schedules }: Props) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState<string>("");
  const [schedData, setSchedData] = useState<SchedulePaymentModel[][]>([[]]);
  const [entries, setEntries] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const [debounceSearch] = useDebounce(searchValue, 500);

  const loading = false;
  const listData = schedData[page - 1];
  const count = schedules.length;

  useEffect(() => {
    let index = entries - 1;
    let newArr: SchedulePaymentModel[][] = [];
    let arr: SchedulePaymentModel[] = [];
    schedules.forEach((item, key) => {
      if (key > index) {
        newArr.push(arr);
        index += entries;
        arr = [item];
      } else if (key === schedules.length - 1) {
        arr.push(item);
        newArr.push(arr);
      } else {
        arr.push(item);
      }
    });

    setSchedData(newArr);
  }, [entries, schedules]);

  return (
    <>
      <Grid container marginBottom={2} alignItems="center">
        <Grid item xs={12} lg={8}>
          <TableSearchField
            searchValue={debounceSearch ?? ""}
            pathname={``}
            querySearch={(value: string) => ""}
            handleSearchSetState={(e) => setSearchValue(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <RowsPerPageComp
            entries={entries}
            pathname=""
            querySearch={(entry) => ""}
            changeEntry={(e) => setEntries(Number(e.target.value))}
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "primary.dark",
              }}
            >
              {headerLabels.map((item, index) => (
                <TableCell
                  key={index}
                  sx={{
                    color: "common.white",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={headerLabels.length} align="center">
                  <CircularProgress
                    color="primary"
                    style={{ margin: "20px 0" }}
                  />
                </TableCell>
              </TableRow>
            ) : listData && listData.length > 0 ? (
              listData.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor: "grey.100",

                      td: {
                        backgroundColor: "grey.100",
                      },
                    },
                    "&:nth-of-type(odd) td": {
                      backgroundColor: "common.white",
                    },
                  }}
                >
                  <TableCell>{item.countTerms}</TableCell>
                  <TableCell>{item.month}</TableCell>
                  <TableCell>{item.amount}</TableCell>
                  <TableCell>{item.status}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  NO DATA FOUND
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        pathname=""
        querySearch={(page) => ""}
        totalCount={count ?? 0}
        currentPage={page}
        pageSize={entries}
        nextPageSetState={() => setPage((state) => state + 1)}
        prevPageSetState={() => setPage((state) => state - 1)}
        selectPageSetState={(page) => setPage(page)}
      />
      <Stack direction="row" spacing={2} alignItems="center" paddingTop={3}>
        <Typography fontWeight={600} color={"primary"}>
          Payment Documents
        </Typography>
        <Button
          variant="button-secondary"
          sx={{
            width: 150,
          }}
          onClick={handleToggleUpload}
        >
          Upload
        </Button>
      </Stack>
    </>
  );
};

export default PaymentSchedule;
