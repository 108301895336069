interface OptType<Tlabel, Tvalue> {
  label: Tlabel;
  value: Tvalue;
}

export const domainLink = process.env.REACT_APP_DOMAIN_LINK as string;

export const gg_user_cred_key = "gg-user-cred";
export const token_key = "token";
export const gg_menu_key = "gg-menu";
export const refresh_token_key = "refreshToken";

// text & labels
export const emailLabel = "Email";
export const passwordLabel = "Password";
export const loginBtnLabel = "Login";
export const forgotPasswordLabel = "Forgot password";
export const confirmPasswordLabel = "Confirm Password";
export const welcomeText = "Welcome!";
export const pleaseSignInText = "Please sign-in to your account";
export const searchText = "Search";
export const profileText = "Profile";
export const logoutText = "Logout";
export const userInformationText = "User Information";
export const roleInformation = "Role Information";
export const createUserText = "Create User";
export const uploadPhotoText = "Choose Picture";
export const changePhotoText = "Change Picture";
export const addRole = "Add Role";
export const nameLabel = "Name";
export const descriptionLabel = "Description";
export const resetPasswordText = "Reset Password";
export const addAgentText = "Add Sales Personnel";

export const thisFieldRequired = "This field is required.";
export const seleactAtleastOne = "Please select atleast 1.";
export const pleaseEnterValidNum = "Please enter a valid number";
export const enterValidMobileNum = "Please enter a valid mobile number";

export const sexOpt: OptType<string, string>[] = [
  {
    label: "MALE",
    value: "MALE",
  },
  {
    label: "FEMALE",
    value: "MALE",
  },
];
export const civilStatusOpt: OptType<string, string>[] = [
  {
    label: "MARRIED",
    value: "MARRIED",
  },
  {
    label: "SINGLE",
    value: "SINGLE",
  },
];
export const agentTypeOpt: OptType<string, string>[] = [
  {
    label: "MARKETING HEAD",
    value: "MARKETING-HEAD",
  },
  {
    label: "AREA SALES DIRECTOR",
    value: "AREA-DIRECTOR",
  },
  {
    label: "AGENT",
    value: "AGENT",
  },
];

export const salesPersonnelOpt: OptType<string, string>[] = [
  {
    label: "MARKETING HEAD",
    value: "MARKETING-HEAD",
  },
  {
    label: "AREA SALES DIRECTOR",
    value: "AREA-DIRECTOR",
  },
  {
    label: "AGENT",
    value: "AGENT",
  },
  {
    label: "CASHIER",
    value: "CASHIER",
  },
  {
    label: "LOCATOR",
    value: "LOCATOR",
  },
];

export const genderOpt: OptType<string, string>[] = [
  {
    label: "Male",
    value: "MALE",
  },
  {
    label: "Female",
    value: "FEMALE",
  },
];

export const identificationOpt: OptType<string, string>[] = [
  {
    label: "SSS",
    value: "SSS",
  },
  {
    label: "Philhealth",
    value: "Philhealth",
  },
  {
    label: "Pag-ibig",
    value: "Pag-ibig",
  },
  {
    label: "TIN",
    value: "TIN",
  },
  {
    label: "UMID",
    value: "UMID",
  },
  {
    label: "Driver's License",
    value: "Driver's License",
  },
  {
    label: "Passport",
    value: "Passport",
  },
  {
    label: "Company ID",
    value: "Company ID",
  },
];

export const scheduledMonthsOpt: OptType<string, number>[] = [
  {
    label: "6 months",
    value: 6,
  },
  {
    label: "12 months",
    value: 12,
  },
  {
    label: "18 months",
    value: 18,
  },
  {
    label: "24 months",
    value: 24,
  },
  {
    label: "30 months",
    value: 30,
  },
  {
    label: "36 months",
    value: 36,
  },
  {
    label: "42 months",
    value: 42,
  },
  {
    label: "48 months",
    value: 48,
  },
  {
    label: "54 months",
    value: 54,
  },
  {
    label: "60 months",
    value: 60,
  },
];

export const receiptOpt: OptType<string, number>[] = [
  {
    label: "Official Receipt",
    value: 1,
  },
  {
    label: "Acknowledgement Receipt",
    value: 2,
  },
  {
    label: "Provisional Receipt",
    value: 3,
  },
];

export const colorPalette: string[] = [
  "#9b745b",
  "#374F2F",
  "#E04347",
  "#56CA00",
  "#FFB400",
  "#16B1FF",
  "#BDBDBD",
  "#000000",
];
