import React from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { styled, useTheme } from "@mui/material/styles";

import TriangleImage from "../../../asset/dashboard/triangle-light.png";
import TrophyImage from "../../../asset/dashboard/trophy.png";

type Props = {};

const TriangleImg = styled("img")({
  right: 0,
  bottom: 0,
  height: 170,
  position: "absolute",
});

// Styled component for the trophy image
const TrophyImg = styled("img")({
  right: 36,
  bottom: 20,
  height: 98,
  position: "absolute",
});

const Trophy = (props: Props) => {
  const theme = useTheme();

  return (
    <Card sx={{ position: "relative" }}>
      <CardContent>
        <Typography variant="h6">Congratulations John! 🥳</Typography>
        <Typography variant="body2" sx={{ letterSpacing: "0.25px" }}>
          Best seller of the month
        </Typography>
        <Typography variant="h5" sx={{ my: 4, color: "primary.main" }}>
          42.8k
        </Typography>
        <Button size="small" variant="contained">
          View Sales
        </Button>
        <TriangleImg alt="triangle background" src={TriangleImage} />
        <TrophyImg alt="trophy" src={TrophyImage} />
      </CardContent>
    </Card>
  );
};

export default Trophy;
