import React, { useEffect, useRef, useMemo, useState } from "react";
import moment from "moment";
import _ from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
  useFieldArray,
} from "react-hook-form";
import CardHeader from "../../common/CardHeader";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import { thisFieldRequired } from "../../../utils/constants";
import CustomFileUploader from "../../common/textfields/CustomFileUploader";
import {
  replaceAllSpaceWithHyphen,
  replaceAllHyphenWithSpace,
} from "../../../utils/helpers/stringManipulate";
import { linkWithDomain } from "../../../utils/helpers/stringManipulate";
import { useGetPaymentsListConfigQuery } from "../../../service/slice/system-configuration/paymentsTypesSlice";
import { useGetBuyerListConfigQuery } from "../../../service/slice/client-management/buyer/buyerListSlice";

import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import { LoaderWithText } from "../../common/Loaders";
import { PaymentFieldsBody } from "../../../model/buyerModel";
import { getUserCredential } from "../../../utils/helpers/storageHelper";
import {
  useAddProvisionalReceiptMutation,
  useLazyGetProvisionalDocQuery,
  useGetProvisionalDetailsQuery,
} from "../../../service/slice/account-management/provisional-receipt/provisionalReceiptSlice";
import useFileView from "../../../hooks/useFileView";
import FileViewModal from "../../common/FileViewModal";
import { skipToken } from "@reduxjs/toolkit/query";
import { ProvisionalReceiptModel } from "../../../model/provisionalReceiptModel";
import ProvisionalUpload from "./ProvisionalUpload";

interface ProvisionalForm {
  paymentType: {
    label: string;
    value: number | null;
  };
  paymentFields: {
    [name: string]: string | Date | null | undefined;
  }[];
  receiveFrom: {
    label: string;
    value: number | null;
  };
  preparedBy: string;
}

type Props = {
  title: string;
  data: ProvisionalReceiptModel | null;
  closeFn: () => void;
};

const CreateProvisional = ({ title, data, closeFn }: Props) => {
  const user = getUserCredential();
  const paymentFieldRef = useRef<string[]>([]);
  const paymentFieldIdRef = useRef<number[]>([]);
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [viewDoc, handelToggleViewDoc] = useFileView();
  const form = useForm<ProvisionalForm>({
    defaultValues: {
      paymentType: {
        label: "",
        value: null,
      },
      paymentFields: [],
      receiveFrom: {
        label: "",
        value: null,
      },
      preparedBy: user ? `${user?.firstName} ${user?.lastName}` : "",
    },
  });

  const {
    data: paymentListData,
    isLoading: paymentListIsLoading,
    error: paymentListError,
  } = useGetPaymentsListConfigQuery();

  const {
    data: buyerList,
    isLoading: buyerIsLoading,
    error: buyerError,
  } = useGetBuyerListConfigQuery();

  const {
    data: details,
    isLoading: detailsIsLoading,
    error: detailsError,
  } = useGetProvisionalDetailsQuery(data ? data.id : skipToken);

  const [
    addMutate,
    {
      data: addData,
      isLoading: addIsLoading,
      isSuccess: addIsSuccess,
      error: addError,
      reset: addReset,
    },
  ] = useAddProvisionalReceiptMutation();

  const [
    getDoc,
    {
      data: docData,
      isLoading: docIsLoading,
      error: docError,
      isSuccess: docIsSuccess,
    },
  ] = useLazyGetProvisionalDocQuery();

  const loading = paymentListIsLoading || buyerIsLoading || detailsIsLoading;
  const saveLoading = addIsLoading || docIsLoading;

  const paymentTypeOptions = useMemo(() => {
    return (
      paymentListData?.data.map((item) => {
        return {
          label: item.name,
          value: item.id!.toString(),
        };
      }) ?? []
    );
  }, [paymentListData?.data]);

  const buyerOptions = useMemo(() => {
    return (
      buyerList?.data.map((item) => {
        return {
          label: `${item.firstName} ${item.lastName}`,
          value: item.id,
        };
      }) ?? []
    );
  }, [buyerList?.data]);

  const { control, setValue, getValues, setError, handleSubmit } = form;

  const paymentTypeWatch = useWatch({
    name: "paymentType",
    control,
  });

  const receiveFromWatch = useWatch({
    name: "receiveFrom",
    control,
  });

  const { fields: paymentFields, replace: paymentFieldsReplace } =
    useFieldArray({
      control,
      name: "paymentFields",
    });

  const toggleUploadDoc = () => setOpenUpload((prev) => !prev);

  const handlePaymentTypeOnChange = (value: {
    label: string;
    value: number | null;
  }) => {
    const selectedPayType = paymentListData?.data?.find(
      (item) => item.id === Number(value.value)
    );

    if (selectedPayType) {
      paymentFieldsReplace(
        selectedPayType.paymentFields.map(
          (item): { [name: string]: string } => ({
            [`${replaceAllSpaceWithHyphen(item.entries)}`]: "",
          })
        )
      );
      paymentFieldRef.current = selectedPayType.paymentFields.map(
        (item) => `${replaceAllSpaceWithHyphen(item.entries)}`
      );
      paymentFieldIdRef.current = selectedPayType.paymentFields.map(
        (item) => item.id
      );

      setValue("paymentType", value);

      setError("paymentType", {
        type: "required",
        message: "",
      });
    }
  };

  const checkErrors = (): number[] => {
    const { paymentType, receiveFrom } = getValues();
    let errors: number[] = [];

    if (paymentType.value === null) {
      setError("paymentType", {
        type: "required",
        message: thisFieldRequired,
      });
      errors.push(1);
    }

    if (receiveFrom.value === null) {
      setError("receiveFrom", {
        type: "required",
        message: thisFieldRequired,
      });
      errors.push(1);
    }

    return errors;
  };

  const onError = () => checkErrors();

  const onSubmit: SubmitHandler<ProvisionalForm> = (datafields) => {
    const errorCount = checkErrors();

    if (errorCount.length > 0) {
      return;
    }

    const { paymentFields, paymentType, receiveFrom } = datafields;

    let finalPaymentAmount: string | number = 0;

    paymentFields.forEach((item, key) => {
      const itemKeyName = paymentFieldRef.current[key];

      if (
        itemKeyName.toLowerCase().includes("cash") ||
        itemKeyName.toLowerCase().includes("amount")
      ) {
        finalPaymentAmount = item[itemKeyName] as string;
      }
    });

    const finalPaymentFields: PaymentFieldsBody[] = paymentFields.map(
      (item, key) => {
        const itemId = paymentFieldIdRef.current[key];
        const itemKeyName = paymentFieldRef.current[key];
        return {
          fieldId: itemId,
          value: itemKeyName.toLowerCase().includes("date")
            ? (moment(item[itemKeyName]).format("YYYY-MM-DD") as string)
            : (item[itemKeyName] as string),
        };
      }
    );

    addMutate({
      buyerId: Number(receiveFrom.value),
      payment: {
        paymentId: Number(paymentType.value),
        amount: finalPaymentAmount,
        paymentFields: finalPaymentFields,
      },
    });
  };

  if (detailsError) {
    errorDisplayOrNavigate({
      error: detailsError,
      toastId: "details",
    });
  }

  if (docError) {
    errorDisplayOrNavigate({
      error: docError,
      toastId: "receipt",
    });
  }

  if (addIsSuccess) {
    showToastSuccess({
      toastId: "add",
      text: "Provisional Receipt Successfully Created!",
    });

    getDoc(addData?.data?.typeId ?? 0);

    addReset();
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add",
    });

    addReset();
  }

  if (buyerError) {
    errorDisplayOrNavigate({
      error: buyerError,
      toastId: "buyer-list",
    });
  }

  if (paymentListError) {
    errorDisplayOrNavigate({
      error: paymentListError,
      toastId: "payment-list",
    });
  }

  useEffect(() => {
    if (details?.data.id) {
      const { payment, preparedBy, receivedFrom } = details.data;

      const { fields, paymentType } = payment;

      const selectedPayType = paymentListData?.data.find(
        (item) => item.id === paymentType.id
      );

      setValue("paymentType", {
        label: selectedPayType?.name ?? "",
        value: selectedPayType?.id ?? null,
      });

      setValue("receiveFrom", {
        label: `${receivedFrom.firstName} ${receivedFrom.lastName}`,
        value: receivedFrom.id,
      });

      setValue("preparedBy", `${preparedBy.firstName} ${preparedBy.lastName}`);

      if (selectedPayType) {
        paymentFieldsReplace(
          selectedPayType.paymentFields.map((item) => {
            const paymentValue = fields.find(
              (itemField) => itemField.field.id === item.id
            );

            const realValue = paymentValue?.field.entries
              .toLowerCase()
              .includes("date")
              ? new Date(paymentValue.value)
              : paymentValue?.value;

            return {
              [`${replaceAllSpaceWithHyphen(item.entries)}`]: realValue,
            };
          })
        );

        paymentFieldRef.current = selectedPayType.paymentFields.map(
          (item) => `${replaceAllSpaceWithHyphen(item.entries)}`
        );
        paymentFieldIdRef.current = selectedPayType.paymentFields.map(
          (item) => item.id
        );
      }
    }
  }, [details, paymentListData]);

  useEffect(() => {
    if (docIsSuccess) {
      handelToggleViewDoc(true, docData?.data.link ?? "");
    }
  }, [docIsSuccess]);

  return (
    <Box paddingBottom={2}>
      {openUpload && (
        <ProvisionalUpload
          handleToggleUpload={toggleUploadDoc}
          id={receiveFromWatch.value ?? 0}
          open={openUpload}
          title={
            receiveFromWatch.value
              ? `Upload Documents (${receiveFromWatch.label})`
              : ""
          }
        />
      )}
      {viewDoc.open && (
        <FileViewModal
          open={viewDoc.open}
          path={viewDoc.path}
          title={viewDoc.path.substring(
            viewDoc.path.lastIndexOf("/") + 1,
            viewDoc.path.length
          )}
          onClose={() => {
            handelToggleViewDoc(false, "");
            if (data === null) {
              closeFn();
            }
          }}
        />
      )}
      <Paper elevation={3}>
        <CardHeader title={title} handleCloseCard={closeFn} />
        <Box padding={3}>
          {loading ? (
            <Box paddingX={2}>
              <Stack alignItems="center" justifyContent="center">
                <LoaderWithText text="Get Additional Details.." />
              </Stack>
            </Box>
          ) : (
            <>
              <FormProvider {...form}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={5}>
                    <Typography variant="textfieldLabel">
                      Received From
                    </Typography>
                    <CustomReactSelectJs
                      control={control}
                      options={buyerOptions}
                      name="receiveFrom"
                      placeholder="Received From"
                    />
                  </Grid>

                  <Grid item xs={12} md={5}>
                    <Typography variant="textfieldLabel">
                      Prepared By
                    </Typography>
                    <Controller
                      name="preparedBy"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          disabled
                          placeholder={"Prepared By"}
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={5}>
                    <Stack spacing={2} width="100%">
                      <div>
                        <Typography variant="textfieldLabel">
                          Payment Type
                        </Typography>
                        <CustomReactSelectJs
                          control={control}
                          options={paymentTypeOptions}
                          name="paymentType"
                          placeholder="Payment Type"
                          // @ts-ignore
                          customOnchange={handlePaymentTypeOnChange}
                        />
                      </div>

                      {!_.isNull(paymentTypeWatch.value) && (
                        <>
                          {paymentFields.map((item, key) => {
                            const itemKeyName = Object.keys(item)[0];

                            const itemLabelName =
                              replaceAllHyphenWithSpace(itemKeyName);

                            return (
                              <div key={key}>
                                <Typography variant="textfieldLabel">
                                  {itemLabelName}
                                </Typography>
                                <Controller
                                  name={`paymentFields.${key}.${itemKeyName}`}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: thisFieldRequired,
                                    },
                                  }}
                                  control={control}
                                  render={({ field, fieldState }) => {
                                    if (
                                      itemKeyName.toLowerCase().includes("date")
                                    ) {
                                      return (
                                        <LocalizationProvider
                                          dateAdapter={AdapterDateFns}
                                        >
                                          <DatePicker
                                            {...field}
                                            slotProps={{
                                              textField: {
                                                variant: "outlined",
                                                error: fieldState.error?.message
                                                  ? true
                                                  : false,
                                                helperText:
                                                  fieldState.error?.message,
                                                fullWidth: true,
                                              },
                                            }}
                                          />
                                        </LocalizationProvider>
                                      );
                                    }
                                    return (
                                      <TextField
                                        {...field}
                                        placeholder={itemLabelName}
                                        error={fieldState.error ? true : false}
                                        helperText={fieldState.error?.message}
                                        sx={{
                                          width: "100%",
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </div>
                            );
                          })}
                        </>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      marginTop={2}
                    >
                      <Typography variant="textfieldLabel">
                        Acknowledge Receipt Documents
                      </Typography>
                      <Button
                        variant="button-secondary"
                        disabled={receiveFromWatch.value ? false : true}
                        sx={{
                          width: 150,
                        }}
                        startIcon={
                          saveLoading && (
                            <CircularProgress
                              size={20}
                              sx={(theme) => ({
                                color: theme.palette.common.white,
                              })}
                            />
                          )
                        }
                        onClick={toggleUploadDoc}
                      >
                        Upload
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider
                  sx={(theme) => ({
                    marginY: theme.spacing(3),
                  })}
                />
                <Stack direction="row" spacing={1} justifyContent="flex-end">
                  {data === null && (
                    <Button
                      variant="button-primary"
                      disabled={data !== null}
                      sx={{
                        width: 150,
                      }}
                      startIcon={
                        saveLoading && (
                          <CircularProgress
                            size={20}
                            sx={(theme) => ({
                              color: theme.palette.common.white,
                            })}
                          />
                        )
                      }
                      onClick={
                        saveLoading ? () => {} : handleSubmit(onSubmit, onError)
                      }
                    >
                      {data ? "Update" : "Save"}
                    </Button>
                  )}

                  <Button
                    variant="button-secondary"
                    disabled={data?.id ? false : true}
                    sx={{
                      width: 150,
                    }}
                    startIcon={
                      docIsLoading && (
                        <CircularProgress
                          size={20}
                          sx={(theme) => ({
                            color: theme.palette.common.white,
                          })}
                        />
                      )
                    }
                    onClick={() => getDoc(data?.id as number)}
                  >
                    Print
                  </Button>
                </Stack>
              </FormProvider>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default CreateProvisional;
