export const loginRoute = "/login";

export const profileRoute = "/profile";
export const userManagementRoute = "/user-management";
export const usersRoute = "/users";
export const rolesRoute = "/roles-and-permission";
export const salesPersonelRoute = "/sales-personnel";
export const systemConfigRoute = "/system-configuration";
export const generalRoute = "/general";
export const chartAccountsRoute = "/chart-of-accounts";
export const paymentsTypesRoute = "/payments-types";
export const expensesTypesRoute = "/expenses-types";
export const discountsPromosRoute = "/discounts-and-promo";
export const commissionSchemeRoute = "/commission-scheme";
export const lotVaultCryptRoute = "/lot-col-crypt-management";
export const lawnLotRoute = "/lawn-lot";
export const cryptRoute = "/crypt";
export const vaultRoute = "/columbarium";
export const clientManagementRoute = "/client-management";
export const buyersRoute = "/buyers";
export const rentalAndServicesRoute = "/rental-and-services";
export const intermentRoute = "/interment";
export const leadsRoute = "/leads";
export const disintermentRoute = "/disinterment";
export const inurnmentRoute = "/inurnment";
export const exhumationRoute = "/exhumation";
export const cremationRoute = "/cremation";
export const accountManagementRoute = "/account-management";
export const officialReceiptRoute = "/official-receipt";
export const paymentRoute = "/payment";
export const otherPaymentRoute = "/other-payment";
export const inventoryManagementRoute = "/inventory-management";
export const inventoryConfigurationRoute = "/inventory-configuration";
export const assetLocationRoute = "/asset-location";
export const suppliersRoute = "/suppliers";
export const trackingRoute = "/tracking";
export const expensesRoute = "/expenses";
export const checkVoucherRoute = "/check-voucher";
export const cashVoucherRoute = "/cash-voucher";
export const acknowledgementReceiptRoute = "/acknowledge-receipt";
export const provisionalReceiptRoute = "/provisional-receipt";
export const fourOOneRoute = "/401";
export const fourOFourRoute = "/404";
export const fiveOORoute = "/500";
