import React, { useEffect, useRef } from "react";
import _ from "lodash";
import moment from "moment";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";

import UploadPhotoField from "../../../../common/textfields/UploadPhotoField";
import BuyerContacts from "./BuyerContacts";
import {
  domainLink,
  enterValidMobileNum,
  genderOpt,
  thisFieldRequired,
} from "../../../../../utils/constants";
import { StepModel, StepperActionCta } from "../../../../common/StepperTabMenu";
import {
  buyersRoute,
  clientManagementRoute,
} from "../../../../../routes/routeKeys";
import {
  useUploadPicMutation,
  useAddBuyerInfoMutation,
  useGetBuyerInformationQuery,
  useUpdateBuyerInfoMutation,
} from "../../../../../service/slice/client-management/buyer/buyerInformationSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../../../utils/notificationToast";
import { skipToken } from "@reduxjs/toolkit/query";
import { LoaderWithText } from "../../../../common/Loaders";
import {
  BuyerInformationBodyModel,
  UploadPicDtoModel,
} from "../../../../../model/buyerModel";
import { UnitModel } from "../unit-type/UnitType";
import { StepperGenericModel } from "../../Buyers";
import CustomReactSelectJs from "../../../../common/textfields/CustomReactSelectJs";

interface AddressModel {
  address: string;
  country: string;
  region: string;
  municipality: string;
  city: string;
  postalCode: string;
}

export interface BuyerForm {
  lastName: string;
  firstName: string;
  middleName: string;
  contactNumber: {
    id: null | number;
    value: string;
  }[];
  email: string;
  birthDate: Date | null;
  homeAddress: AddressModel;
  mailingAddress: AddressModel;
  sameHomeAddress: boolean;
  picture: {
    file: string | null;
    originalName: string | null;
    filetype: string | null;
    rawFile: FileList | null;
  };
  gender: {
    label: string;
    value: null | "MALE" | "FEMALE";
  };
}

type Props = {
  steps: StepModel[];
};

const BuyerInformation = ({ steps }: Props) => {
  const navigate = useNavigate();
  const { state: locState } = useLocation();
  const { id } = useParams();
  const buyerId = id ? Number(id) : null;

  const form = useForm<BuyerForm>({
    defaultValues: {
      lastName: "",
      firstName: "",
      middleName: "",
      email: "",
      birthDate: null,
      contactNumber: [
        {
          id: null,
          value: "",
        },
      ],
      homeAddress: {
        address: "",
        city: "",
        country: "",
        municipality: "",
        postalCode: "",
        region: "",
      },
      mailingAddress: {
        address: "",
        city: "",
        country: "",
        municipality: "",
        postalCode: "",
        region: "",
      },
      sameHomeAddress: false,
      picture: {
        file: null,
        filetype: null,
        originalName: null,
        rawFile: null,
      },
      gender: {
        label: "",
        value: null,
      },
    },
  });

  const {
    data: buyerInfoData,
    isLoading: buyerInfoIsLoading,
    error: buyerInfoError,
  } = useGetBuyerInformationQuery(buyerId ?? skipToken);

  const [
    uploadPicMutate,
    {
      isLoading: uploadPicIsLoading,
      error: uploadPicError,
      reset: uploadPicReset,
    },
  ] = useUploadPicMutation();

  const [
    addMutate,
    {
      data: addData,
      error: addError,
      isLoading: addIsLoading,
      reset: addReset,
      isSuccess: addIsSuccess,
    },
  ] = useAddBuyerInfoMutation();

  const [
    updateMutate,
    {
      error: updateError,
      isLoading: updateIsLoading,
      reset: updateReset,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateBuyerInfoMutation();

  const {
    control,
    setValue,
    reset: fieldReset,
    handleSubmit,
    setError,
    formState: { errors },
    getValues,
    clearErrors,
    watch,
  } = form;

  const pictureWatch = useWatch({
    control,
    name: "picture",
  });

  const mobileNumWatch = useWatch({
    control,
    name: "contactNumber",
  });

  const sameHomeAddressWatch = useWatch({
    control,
    name: "sameHomeAddress",
  });

  const homeAddressWatch = useWatch({
    control,
    name: "homeAddress",
  });

  const genderWatch = useWatch({ control, name: "gender" });

  // const allFieldsWatch = watch(["picture"]);

  const saveLoading = uploadPicIsLoading || addIsLoading || updateIsLoading;
  const loading = buyerInfoIsLoading;

  const validateOtherFields = (): number[] => {
    const errorsArr = [];

    if (_.isEmpty(mobileNumWatch[0].value)) {
      setError(`contactNumber.${0}.value`, {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    } else {
      mobileNumWatch.forEach((item, key) => {
        if (!_.isEmpty(item.value)) {
          const formatVal = item.value.replaceAll(" ", "").replace("+63", "");
          if (formatVal.length > 10 || formatVal.length < 10) {
            setError(`contactNumber.${key}.value`, {
              type: "required",
              message: enterValidMobileNum,
            });

            errorsArr.push(1);
          }
        }
      });
    }

    if (_.isNull(genderWatch.value)) {
      setError("gender", {
        type: "required",
        message: thisFieldRequired,
      });

      errorsArr.push(1);
    }

    return errorsArr;
  };

  const onError = () => {
    validateOtherFields();
  };

  const onSubmit: SubmitHandler<BuyerForm> = async (dataFields) => {
    const otherErrors = validateOtherFields();

    if (otherErrors.length > 0) {
      return;
    }

    const finalDateBirth: string = moment(dataFields.birthDate).format(
      "YYYY-MM-DD"
    );

    let dataPic: UploadPicDtoModel | null = null;
    if (buyerId) {
      const newBody: Partial<BuyerInformationBodyModel> = {
        gender: dataFields.gender.value,
        birthDate: finalDateBirth,
        contact: dataFields.contactNumber
          .map((item) => ({
            number: item.value,
            id: item.id,
          }))
          .filter((item) => !_.isEmpty(item.number)),
        email: dataFields.email,
        firstName: dataFields.firstName,
        lastName: dataFields.lastName,
        middleName: dataFields.middleName,
        homeAddress: dataFields.homeAddress.address,
        homeCity: dataFields.homeAddress.city,
        homeCountry: dataFields.homeAddress.country,
        homeMunicipality: dataFields.homeAddress.municipality,
        homePostalCode: dataFields.homeAddress.postalCode,
        homeRegion: dataFields.homeAddress.region,
        mailingAddress: dataFields.mailingAddress.address,
        mailingCity: dataFields.mailingAddress.city,
        mailingCountry: dataFields.mailingAddress.country,
        mailingMunicipality: dataFields.mailingAddress.municipality,
        mailingPostalCode: dataFields.mailingAddress.postalCode,
        mailingRegion: dataFields.mailingAddress.region,
        sameAddress: dataFields.sameHomeAddress ? 1 : 0,
      };

      if (dataFields.picture.rawFile) {
        dataPic = await uploadPicMutate(dataFields.picture.rawFile).unwrap();
      }

      await updateMutate({
        queryParam: buyerId,
        bodyParam: {
          ...newBody,
          picture: dataFields.picture.rawFile
            ? (dataPic?.data.link as string)
            : dataFields.picture.file
            ? dataFields.picture.file?.replace(domainLink, "")
            : dataPic
            ? dataPic?.data.link
            : null,
        },
      });
    } else {
      if (dataFields.picture.rawFile) {
        dataPic = await uploadPicMutate(dataFields.picture.rawFile).unwrap();
      }

      await addMutate({
        gender: dataFields.gender.value ?? null,
        birthDate: finalDateBirth,
        contact: dataFields.contactNumber.map((item) => ({
          number: item.value,
          id: null,
        })),
        email: dataFields.email,
        firstName: dataFields.firstName,
        lastName: dataFields.lastName,
        middleName: dataFields.middleName,
        picture: dataPic ? dataPic?.data.link : null,
        homeAddress: dataFields.homeAddress.address,
        homeCity: dataFields.homeAddress.city,
        homeCountry: dataFields.homeAddress.country,
        homeMunicipality: dataFields.homeAddress.municipality,
        homePostalCode: dataFields.homeAddress.postalCode,
        homeRegion: dataFields.homeAddress.region,
        mailingAddress: dataFields.mailingAddress.address,
        mailingCity: dataFields.mailingAddress.city,
        mailingCountry: dataFields.mailingAddress.country,
        mailingMunicipality: dataFields.mailingAddress.municipality,
        mailingPostalCode: dataFields.mailingAddress.postalCode,
        mailingRegion: dataFields.mailingAddress.region,
        sameAddress: dataFields.sameHomeAddress ? 1 : 0,
      });
    }
  };

  if (buyerInfoError) {
    errorDisplayOrNavigate({
      error: buyerInfoError,
      toastId: "buyer-info",
    });
  }

  if (uploadPicError) {
    errorDisplayOrNavigate({
      error: uploadPicError,
      toastId: "upload-pic",
    });

    uploadPicReset();
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add",
    });

    addReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "New buyer added.",
      toastId: "add",
    });

    addReset();
    navigate(
      `${clientManagementRoute}${buyersRoute}/edit/${addData?.data.id}/other-information`,
      {
        state: locState,
      }
    );
    // fieldReset();
  }

  if (updateError) {
    errorDisplayOrNavigate({
      error: updateError,
      toastId: "update-info",
    });

    updateReset();
  }

  if (updateIsSuccess) {
    showToastSuccess({
      text: "Buyer information updated!",
      toastId: "update-info",
    });

    updateReset();
  }

  useEffect(() => {
    const { homeAddress, mailingAddress } = getValues();
    if (sameHomeAddressWatch) {
      setValue("mailingAddress.address", homeAddress.address);
      setValue("mailingAddress.city", homeAddress.city);
      setValue("mailingAddress.country", homeAddress.country);
      setValue("mailingAddress.municipality", homeAddress.municipality);
      setValue("mailingAddress.postalCode", homeAddress.postalCode);
      setValue("mailingAddress.region", homeAddress.region);
      clearErrors([
        "mailingAddress.address",
        "mailingAddress.city",
        "mailingAddress.country",
        "mailingAddress.municipality",
        "mailingAddress.postalCode",
        "mailingAddress.region",
      ]);
    } else {
      setValue("mailingAddress.address", mailingAddress.address);
      setValue("mailingAddress.city", mailingAddress.city);
      setValue("mailingAddress.country", mailingAddress.country);
      setValue("mailingAddress.municipality", mailingAddress.municipality);
      setValue("mailingAddress.postalCode", mailingAddress.postalCode);
      setValue("mailingAddress.region", mailingAddress.region);
    }
  }, [sameHomeAddressWatch, homeAddressWatch]);

  useEffect(() => {
    // if (dataFromStorage) {
    //   setValue("lastName", dataFromStorage.lastName);
    // }
    if (buyerInfoData || locState?.buyerInfo) {
      const {
        firstName,
        lastName,
        birthDate,
        buyerContacts,
        email,
        homeAddress,
        homeCity,
        homeCountry,
        homeMunicipality,
        homePostalCode,
        homeRegion,
        mailingAddress,
        mailingCity,
        mailingCountry,
        mailingMunicipality,
        mailingPostalCode,
        mailingRegion,
        middleName,
        gender,
        picture,
        sameAddress,
      } = buyerInfoData?.data ? buyerInfoData.data : locState.buyerInfo;
      const genderValue = gender
        ? (genderOpt.find((item) => item.value === gender) as {
            label: string;
            value: "MALE" | "FEMALE" | null;
          })
        : {
            label: "",
            value: null,
          };
      setValue("gender", genderValue);
      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("middleName", middleName);
      setValue("email", email);
      setValue("birthDate", new Date(birthDate));
      setValue(
        "contactNumber",
        buyerContacts.map((item: { id: number | null; number: string }) => ({
          id: locState?.buyerInfo ? null : item.id,
          value: item.number,
        }))
      );
      setValue("homeAddress.address", homeAddress);
      setValue("homeAddress.city", homeCity);
      setValue("homeAddress.country", homeCountry);
      setValue("homeAddress.municipality", homeMunicipality);
      setValue("homeAddress.postalCode", homePostalCode);
      setValue("homeAddress.region", homeRegion);
      setValue("mailingAddress.address", mailingAddress);
      setValue("mailingAddress.city", mailingCity);
      setValue("mailingAddress.country", mailingCountry);
      setValue("mailingAddress.municipality", mailingMunicipality);
      setValue("mailingAddress.postalCode", mailingPostalCode);
      setValue("mailingAddress.region", mailingRegion);
      setValue(
        "picture",
        picture
          ? {
              file: picture.includes(domainLink)
                ? picture
                : `${domainLink}/${picture}`,
              originalName: "",
              filetype: "",
              rawFile: null,
            }
          : {
              file: null,
              originalName: "",
              filetype: "",
              rawFile: null,
            }
      );
      setValue("sameHomeAddress", sameAddress);
    }
  }, [buyerInfoData, locState?.buerInfo]);

  return loading ? (
    <Box paddingY={3}>
      <LoaderWithText text="Getting buyer information.." />
    </Box>
  ) : (
    <FormProvider {...form}>
      <Grid
        container
        spacing={3}
        direction={{ xs: "column", md: "row-reverse" }}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="textfieldLabel">Photo</Typography>
          <UploadPhotoField
            name="picture"
            setValue={setValue}
            clearErrors={clearErrors}
            // pictureValue={allFieldsWatch[1]}
            pictureValue={pictureWatch}
            errorMsg={errors.picture?.message}
            changeText="Change photo"
            chooseText="Select photo"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <div>
              <Typography variant="textfieldLabel">Last name</Typography>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="Last Name"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Typography variant="textfieldLabel">First name</Typography>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="First Name"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Typography variant="textfieldLabel">Middle name</Typography>
              <Controller
                name="middleName"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="Middle Name"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Typography variant="textfieldLabel">Gender</Typography>
              <CustomReactSelectJs
                control={control}
                options={genderOpt}
                name="gender"
                placeholder="Gender"
              />
            </div>
            <div>
              <Typography variant="textfieldLabel">Email address</Typography>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="email"
                    placeholder="Email Address"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <BuyerContacts
              clearErrors={clearErrors}
              control={control}
              errors={errors}
              setValue={setValue}
            />
            <div>
              <Typography variant="textfieldLabel">Date of Birth</Typography>
              <Controller
                name="birthDate"
                control={control}
                render={({ field, fieldState }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      {...field}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          error: fieldState.error?.message ? true : false,
                          helperText: fieldState.error?.message,
                          fullWidth: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div
            style={{
              width: "100%",
              height: 40,
            }}
          />
          <Stack spacing={2}>
            <div>
              <Typography variant="textfieldLabel">Home address</Typography>
              <Controller
                name="homeAddress.address"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="Home Address"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Typography variant="textfieldLabel">Country</Typography>
              <Controller
                name="homeAddress.country"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="Country"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Typography variant="textfieldLabel">Region</Typography>
              <Controller
                name="homeAddress.region"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="Region"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Typography variant="textfieldLabel">Municipality</Typography>
              <Controller
                name="homeAddress.municipality"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="Municipality"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Typography variant="textfieldLabel">City</Typography>
              <Controller
                name="homeAddress.city"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="City"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Typography variant="textfieldLabel">Postal code</Typography>
              <Controller
                name="homeAddress.postalCode"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="Postal code"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="sameHomeAddress"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                sx={{
                  label: {
                    fontSize: ".9rem",
                  },
                }}
                label="Same as home address"
                control={<Checkbox {...field} checked={field.value} />}
              />
            )}
          />
          <Stack spacing={2}>
            <div>
              <Typography variant="textfieldLabel">Mailing address</Typography>
              <Controller
                name="mailingAddress.address"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    disabled={sameHomeAddressWatch}
                    placeholder="Mailing Address"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Typography variant="textfieldLabel">Country</Typography>
              <Controller
                name="mailingAddress.country"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    disabled={sameHomeAddressWatch}
                    placeholder="Country"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Typography variant="textfieldLabel">Region</Typography>
              <Controller
                name="mailingAddress.region"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    disabled={sameHomeAddressWatch}
                    placeholder="Region"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Typography variant="textfieldLabel">Municipality</Typography>
              <Controller
                name="mailingAddress.municipality"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    disabled={sameHomeAddressWatch}
                    placeholder="Municipality"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Typography variant="textfieldLabel">City</Typography>
              <Controller
                name="mailingAddress.city"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    disabled={sameHomeAddressWatch}
                    placeholder="City"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Typography variant="textfieldLabel">Postal code</Typography>
              <Controller
                name="mailingAddress.postalCode"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: thisFieldRequired,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    disabled={sameHomeAddressWatch}
                    placeholder="Postal code"
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
          </Stack>
        </Grid>
      </Grid>
      <Divider
        sx={(theme) => ({
          marginY: theme.spacing(3),
        })}
      />

      <StepperActionCta<StepperGenericModel>
        // disabled={buyerId ? false : true}
        disabled={false}
        path={
          buyerId
            ? `${clientManagementRoute}${buyersRoute}/edit/${buyerId}`
            : `${clientManagementRoute}${buyersRoute}/add`
        }
        steps={steps}
        state={locState}
      >
        <Button
          variant="button-primary"
          sx={{
            width: 150,
          }}
          startIcon={
            saveLoading && (
              <CircularProgress
                size={20}
                sx={(theme) => ({
                  color: theme.palette.common.white,
                })}
              />
            )
          }
          onClick={handleSubmit(onSubmit, onError)}
        >
          {buyerId ? "Update" : "Save"}
        </Button>
      </StepperActionCta>
    </FormProvider>
  );
};

export default BuyerInformation;
