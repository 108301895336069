import React from "react";
import Grid from "@mui/material/Grid";

import Trophy from "./sub/Trophy";
import StatisticsCard from "./sub/StatisticsCard";
import TaskDashboard from "./sub/TaskDashboard";
import CounterDashboard from "./sub/CounterDashboard";
import FooterPanel from "../common/FooterPanel";

type Props = {};

const Dashboard = (props: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Trophy />
      </Grid>
      <Grid item xs={12} md={8}>
        <StatisticsCard />
      </Grid>
      <Grid item xs={12} md={7}>
        <TaskDashboard />
      </Grid>
      <Grid item xs={12} md={5}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CounterDashboard title="New Client" count={862} />
          </Grid>
          <Grid item xs={6}>
            <CounterDashboard title="Receivables" count={862} />
          </Grid>
          <Grid item xs={6}>
            <CounterDashboard title="Delinquent" count={862} />
          </Grid>
          <Grid item xs={6}>
            <CounterDashboard title="Forfeited" count={862} />
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item xs={12}>
        <FooterPanel />
      </Grid> */}
    </Grid>
  );
};

export default Dashboard;
