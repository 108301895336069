import React from "react";
import { useTheme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { mdiPencil } from "@mdi/js";

import IconButtonMdi from "../../common/IconButtonMdi";
import CustomPagination from "../../common/CustomPagination";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import TableSearchField from "../../common/textfields/TableSearchField";

import useTableParameters from "../../../hooks/useTableParameters";
import { useGetAcknowledgeReceiptListQuery } from "../../../service/slice/account-management/acknowledge-receipt/acknowledgeReceiptSlice";
import {
  accountManagementRoute,
  acknowledgementReceiptRoute,
} from "../../../routes/routeKeys";

import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";

import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import { AcknowledgeReceiptModel } from "../../../model/acknowledgeReceiptModel";
import CreateAcknowledge from "./CreateAcknowledge";

const headerLabels: string[] = [
  "AR Number",
  "Received From",
  "Prepared by",
  "Payment Type",
  "Amount",
];

const AcknowledgementReceipt = () => {
  const { pageParam, entriesParam, searchParam } = useTableParameters();
  const theme = useTheme();
  const [openCreate, toggleOpenCreatForm] =
    useOpenCreateForm<AcknowledgeReceiptModel>();

  const {
    data: listData,
    isLoading: listIsLoading,
    error: listError,
    isFetching: listIsFetching,
  } = useGetAcknowledgeReceiptListQuery({
    entries: entriesParam,
    page: pageParam - 1,
    search: searchParam ?? "",
  });

  const loading = listIsLoading || listIsFetching;
  const list = listData?.data.rows ?? [];
  const totalData = listData?.data.count ?? 0;

  if (listError) {
    errorDisplayOrNavigate({
      error: listError,
      toastId: "acknowledge-receipt-list",
    });
  }

  return (
    <>
      {openCreate.open && (
        <CreateAcknowledge
          data={openCreate.data}
          title={openCreate.title}
          closeFn={() => toggleOpenCreatForm(false, null, "")}
        />
      )}
      <Paper elevation={3}>
        <Box padding={3}>
          <Grid container alignItems="center" marginBottom={2}>
            <Grid item xs={12} lg={8}>
              <Stack direction="row" spacing={1} alignItems="center">
                <TableSearchField
                  searchValue={searchParam ?? ""}
                  pathname={`${accountManagementRoute}${acknowledgementReceiptRoute}`}
                  querySearch={(value: string) =>
                    `?page=${pageParam}&entries=${entriesParam}&search=${value}`
                  }
                />
                <Button
                  variant="button-secondary"
                  onClick={() =>
                    toggleOpenCreatForm(
                      true,
                      null,
                      "Create Acknowledge Receipt"
                    )
                  }
                  sx={{
                    width: "180px",
                  }}
                >
                  Create AR
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <RowsPerPageComp
                entries={entriesParam}
                pathname={`${accountManagementRoute}${acknowledgementReceiptRoute}`}
                querySearch={(entry: string) =>
                  `?page=${pageParam}&entries=${entry}&search=${searchParam}`
                }
              />
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headerLabels.map((item, key) => (
                    <TableCell key={key} variant="head">
                      {item}
                    </TableCell>
                  ))}
                  <TableCell
                    variant="head"
                    sx={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <CircularProgress
                        color="primary"
                        style={{ margin: "20px 0" }}
                      />
                    </TableCell>
                  </TableRow>
                ) : list && list.length > 0 ? (
                  list.map((item, key) => (
                    <TableRow
                      key={key}
                      sx={{
                        "&:nth-of-type(even)": {
                          backgroundColor: "grey.100",

                          td: {
                            backgroundColor: "grey.100",
                          },
                        },
                        "&:nth-of-type(odd) td": {
                          backgroundColor: "common.white",
                        },
                      }}
                    >
                      <TableCell>{item.arNumber}</TableCell>
                      <TableCell>{`${item.receivedFrom.firstName} ${item.receivedFrom.lastName}`}</TableCell>
                      <TableCell>{`${item.preparedBy.firstName} ${item.preparedBy.lastName}`}</TableCell>
                      <TableCell>{item.paymentType.name}</TableCell>
                      <TableCell>{item.amount}</TableCell>
                      <TableCell
                        sx={{
                          position: "sticky",
                          right: 0,
                          zIndex: 800,
                          width: "auto",
                        }}
                      >
                        <Stack direction="row" spacing={1} alignItems="center">
                          <IconButtonMdi
                            mdiIcon={mdiPencil}
                            iconColor={theme.palette.primary.main}
                            tooltipTitle="Edit"
                            onClick={() =>
                              toggleOpenCreatForm(
                                true,
                                item,
                                `Edit Acknowledge Receipt (${item.arNumber})`
                              )
                            }
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      NO DATA FOUND
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            pathname={`${accountManagementRoute}${acknowledgementReceiptRoute}`}
            querySearch={(page) =>
              `?page=${page}&entries=${entriesParam}&search=${searchParam}`
            }
            totalCount={totalData}
            currentPage={pageParam}
            pageSize={entriesParam}
          />
        </Box>
      </Paper>
    </>
  );
};

export default AcknowledgementReceipt;
