export enum PasswordIndicators {
  passwordMatch = "PASSWORD_MATCH",
  passwordMinCharacter = "PASSWORD_MIN_CHAR",
  passwordSpecialChar = "PASSWORD_SPECIAL_CHAR",
  passwordNumber = "PASSWORD_NUMBER",
  passwordUppercase = "PASSWORD_UPPERCASE",
}

export enum StatusEnum {
  enabled = "ENABLED",
  disabled = "DISABLED",
}

export enum RoleTypeEnum {
  agent = "AGENT",
  areaDirector = "AREA-DIRECTOR",
  marketingHead = "MARKETING-HEAD",
  cashier = "CASHIER",
  locator = "LOCATOR",
}
