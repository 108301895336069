import {
  AcknowledgeReceiptBody,
  AcknowledgeReceiptModel,
  GetAcknowledgeReceiptModel,
} from "../../../../model/acknowledgeReceiptModel";
import {
  AddPaymentDocBody,
  PaymentDocDtoModel,
} from "../../../../model/buyerModel";
import {
  ListFetchQueryType,
  ListFetchDtoType,
  FetchDtoType,
  DocLinkFetchDtoType,
} from "../../../../model/global";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../../apiSlice";

export const endpoints = {
  "get-acknowledge-receipt-list": (
    page: number,
    entries: number,
    search: string
  ) =>
    `/account-management/payment/acknowledgement-receipt/list?perPage=${entries}&currentPage=${page}&search=${search}`,
  "add-acknowledge-receipt":
    "/account-management/payment/acknowledgement-receipt/add",
  "print-acknowledge-receipt": (id: number) =>
    `/account-management/payment/acknowledgement-receipt/print/${id}`,
  "acknowledge-receipt-details": (id: number) =>
    `/account-management/payment/acknowledgement-receipt/get/${id}`,
  "upload-acknowledge-receipt-doc": "/buyer/upload/payment",
  "add-acknowledge-receipt-doc": "/buyer/payment/file/add",
  "get-acknowledge-receipt-doc-list": (id: number) =>
    `/buyer/payment/file/get/${id}`,
  "delete-acknowledge-receipt-doc": (id: number) =>
    `/buyer/payment/file/delete/${id}`,
};

export const acknowledgeReceiptSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAcknowledgeReceiptList: builder.query<
      ListFetchDtoType<AcknowledgeReceiptModel[]>,
      ListFetchQueryType
    >({
      query: (param) => ({
        url: endpoints["get-acknowledge-receipt-list"](
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, args) => ["acknowledge-receipt-list"],
    }),
    addAcknowledgeReceipt: builder.mutation<
      FetchDtoType<{ typeId: number }>,
      AcknowledgeReceiptBody
    >({
      query: (body) => ({
        url: endpoints["add-acknowledge-receipt"],
        body,
        method: "POST",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, args) => ["acknowledge-receipt-list"],
    }),
    getAcknowledgeDoc: builder.query<DocLinkFetchDtoType, number>({
      query: (param) => ({
        url: endpoints["print-acknowledge-receipt"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        { type: "acknowledge-receipt-doc", id: arg },
      ],
    }),
    getAcknowledgeDetails: builder.query<
      FetchDtoType<GetAcknowledgeReceiptModel>,
      number
    >({
      query: (param) => ({
        url: endpoints["acknowledge-receipt-details"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        { type: "acknowledge-receipt", id: arg },
      ],
    }),
    addAcknowledgeDoc: builder.mutation<void, AddPaymentDocBody>({
      query: (body) => ({
        url: endpoints["add-acknowledge-receipt-doc"],
        body,
        method: "POST",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => [
        {
          type: "acknowledge-receipt-doc",
          id: arg.buyerPaymentId,
        },
      ],
    }),
    getAcknowledgeDocsList: builder.query<PaymentDocDtoModel, number>({
      query: (param) => ({
        url: endpoints["get-acknowledge-receipt-doc-list"](param),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "acknowledge-receipt-doc",
          id: arg,
        },
      ],
    }),
    deleteAcknowledgeDoc: builder.mutation<
      void,
      { id: number; buyerPaymentId: number }
    >({
      query: (param) => ({
        url: endpoints["delete-acknowledge-receipt-doc"](param.id),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => [
        { type: "acknowledge-receipt-doc", id: arg.buyerPaymentId },
      ],
    }),
  }),
});

export const {
  useGetAcknowledgeReceiptListQuery,
  useAddAcknowledgeReceiptMutation,
  useLazyGetAcknowledgeDocQuery,
  useGetAcknowledgeDetailsQuery,
  useAddAcknowledgeDocMutation,
  useGetAcknowledgeDocsListQuery,
  useDeleteAcknowledgeDocMutation,
} = acknowledgeReceiptSlice;
