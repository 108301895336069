import React from "react";
import { useTheme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { mdiPencil } from "@mdi/js";

import IconButtonMdi from "../../common/IconButtonMdi";
import CustomPagination from "../../common/CustomPagination";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import TableSearchField from "../../common/textfields/TableSearchField";
import useTableParameters from "../../../hooks/useTableParameters";
import useOpenCreateForm from "../../../hooks/useOpenCreateForm";
import {
  accountManagementRoute,
  cashVoucherRoute,
} from "../../../routes/routeKeys";
import CreateVoucher from "./CreateVoucher";
import { VoucherModel } from "../../../model/voucherModel";
import { useGetVoucherListQuery } from "../../../service/slice/account-management/voucher/vouchersSlice";
import { errorDisplayOrNavigate } from "../../../utils/notificationToast";

const headerLabels: string[] = [
  "CV Number",
  "Received By",
  "Prepared By",
  "Checked By",
  "Approved By",
  "Amount",
];

const CashVoucher = () => {
  const { pageParam, entriesParam, searchParam } = useTableParameters();
  const theme = useTheme();
  const [openCreate, toggleOpenCreatForm] = useOpenCreateForm<VoucherModel>();

  const {
    data: listData,
    isLoading: listIsLoading,
    error: listError,
    isFetching: listIsFetching,
  } = useGetVoucherListQuery({
    type: "CASH",
    entries: entriesParam,
    page: pageParam - 1,
    search: searchParam ?? "",
  });

  const loading = listIsLoading || listIsFetching;
  const list = listData?.data?.rows ?? [];
  const totalData = listData?.data.count ?? 0;

  if (listError) {
    errorDisplayOrNavigate({
      error: listError,
      toastId: "lis-check",
    });
  }

  return (
    <>
      {openCreate.open && (
        <CreateVoucher<VoucherModel>
          closeFn={() => toggleOpenCreatForm(false, null, "")}
          data={openCreate.data}
          title={openCreate.title}
          type="CASH"
        />
      )}
      <Paper elevation={3}>
        <Box padding={3}>
          <Grid container alignItems="center" marginBottom={2}>
            <Grid item xs={12} lg={8}>
              <Stack direction="row" spacing={1} alignItems="center">
                <TableSearchField
                  searchValue={searchParam ?? ""}
                  pathname={`${accountManagementRoute}${cashVoucherRoute}`}
                  querySearch={(value: string) =>
                    `?page=${pageParam}&entries=${entriesParam}&search=${value}`
                  }
                />
                <Button
                  variant="button-secondary"
                  onClick={() =>
                    toggleOpenCreatForm(true, null, "Create Cash Voucher")
                  }
                  sx={{
                    width: "180px",
                  }}
                >
                  Create CV
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <RowsPerPageComp
                entries={entriesParam}
                pathname={`${accountManagementRoute}${cashVoucherRoute}`}
                querySearch={(entry: string) =>
                  `?page=${pageParam}&entries=${entry}&search=${searchParam}`
                }
              />
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headerLabels.map((item, key) => (
                    <TableCell key={key} variant="head">
                      {item}
                    </TableCell>
                  ))}
                  <TableCell
                    variant="head"
                    sx={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      width: "auto",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress
                        color="primary"
                        style={{ margin: "20px 0" }}
                      />
                    </TableCell>
                  </TableRow>
                ) : list && list.length > 0 ? (
                  list.map((item, key) => (
                    <TableRow
                      key={key}
                      sx={{
                        "&:nth-of-type(even)": {
                          backgroundColor: "grey.100",

                          td: {
                            backgroundColor: "grey.100",
                          },
                        },
                        "&:nth-of-type(odd) td": {
                          backgroundColor: "common.white",
                        },
                      }}
                    >
                      <TableCell>{item.cvNumber}</TableCell>
                      <TableCell>{`${item.receivedByCC.firstName} ${item.receivedByCC.lastName}`}</TableCell>
                      <TableCell>{`${item.preparedByCC.firstName} ${item.preparedByCC.lastName}`}</TableCell>
                      <TableCell>{`${item.checkedByCC.firstName} ${item.checkedByCC.lastName}`}</TableCell>
                      <TableCell>{`${item.approvedByCC.firstName} ${item.approvedByCC.lastName}`}</TableCell>
                      <TableCell>{item.totalAmount}</TableCell>
                      <TableCell
                        sx={{
                          position: "sticky",
                          right: 0,
                          zIndex: 800,
                          width: "auto",
                        }}
                      >
                        <Stack direction="row" spacing={1} alignItems="center">
                          <IconButtonMdi
                            mdiIcon={mdiPencil}
                            iconColor={theme.palette.primary.main}
                            tooltipTitle="Edit"
                            onClick={() =>
                              toggleOpenCreatForm(
                                true,
                                item,
                                `Edit Cash Voucher (CV Number: ${item.cvNumber})`
                              )
                            }
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      NO DATA FOUND
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            pathname={`${accountManagementRoute}${cashVoucherRoute}`}
            querySearch={(page) =>
              `?page=${page}&entries=${entriesParam}&search=${searchParam}`
            }
            totalCount={totalData}
            currentPage={pageParam}
            pageSize={entriesParam}
          />
        </Box>
      </Paper>
    </>
  );
};

export default CashVoucher;
