import React, { useMemo, useRef, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
  useFieldArray,
} from "react-hook-form";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

import { useGetPaymentsListConfigQuery } from "../../../service/slice/system-configuration/paymentsTypesSlice";
import {
  useAddPaymentAccountManagMutation,
  useLazyGetAccountManagReceiptQuery,
} from "../../../service/slice/account-management/payment/AccountManagementPaymentSlice";

import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import {
  receiptOpt,
  scheduledMonthsOpt,
  thisFieldRequired,
} from "../../../utils/constants";
import {
  replaceAllSpaceWithHyphen,
  replaceAllHyphenWithSpace,
} from "../../../utils/helpers/stringManipulate";
import { PaymentFieldsBody } from "../../../model/buyerModel";
import useFileView from "../../../hooks/useFileView";
import FileViewModal from "../../common/FileViewModal";
import { AccountManagementUnitPaymentDetailsDtoModel } from "../../../model/accountManagementPaymentModel";

interface PaymentForm {
  paymentType: {
    label: string | null;
    value: number | null;
  };
  paymentFields: {
    [name: string]: string | Date | null | undefined;
  }[];
  receipt: {
    label: string | null;
    value: string | null;
  };
  scheduledMonths: {
    label: string | null;
    value: number | string | null;
  };
}

const PaymentForm = ({
  id,
  type,
  detailsData,
}: {
  id: number;
  type: string;
  detailsData?: AccountManagementUnitPaymentDetailsDtoModel;
}) => {
  const paymentFieldRef = useRef<string[]>([]);
  const paymentFieldIdRef = useRef<number[]>([]);
  const [viewDoc, handelToggleViewDoc] = useFileView();

  const {
    data: paymentListData,
    isLoading: paymentListIsLoading,
    error: paymentListError,
  } = useGetPaymentsListConfigQuery();

  const [
    getReceiptTrigger,
    { data: receiptData, isSuccess: receiptIsSuccess, error: receiptError },
  ] = useLazyGetAccountManagReceiptQuery();

  const [
    addMutate,
    {
      isLoading: addIsLoading,
      error: addError,
      reset: addReset,
      isSuccess: addIsSuccess,
      data: addData,
    },
  ] = useAddPaymentAccountManagMutation();

  const paymentTypeOptions = useMemo(() => {
    return (
      paymentListData?.data.map((item) => {
        return {
          label: item.name,
          value: item.id!.toString(),
        };
      }) ?? []
    );
  }, [paymentListData?.data]);

  const form = useForm<PaymentForm>({
    defaultValues: {
      paymentFields: [],
      paymentType: {
        label: "",
        value: null,
      },
      scheduledMonths: {
        label: null,
        value: null,
      },
      receipt: {
        label: null,
        value: null,
      },
    },
  });

  const { control, handleSubmit, setError, clearErrors, getValues, setValue } =
    form;

  const paymentTypeWatch = useWatch({
    name: "paymentType",
    control,
  });

  const receiptWatch = useWatch({
    control,
    name: "receipt",
  });

  const checkErrors = (): number[] => {
    const dataFields = getValues();
    let errors: number[] = [];

    if (dataFields.paymentType.value === null) {
      setError("paymentType", {
        type: "required",
        message: thisFieldRequired,
      });
      errors.push(1);
    }

    if (dataFields.receipt.value === null) {
      setError("receipt", {
        type: "required",
        message: thisFieldRequired,
      });
      errors.push(1);
    }

    return errors;
  };

  const { fields: paymentFieldsWatch, replace: paymentFieldsReplace } =
    useFieldArray({
      control,
      name: "paymentFields",
    });

  const loading = paymentListIsLoading;
  const saveLoading = addIsLoading;

  const onSubmit: SubmitHandler<PaymentForm> = (dataFields) => {
    const errorCount = checkErrors();

    if (errorCount.length > 0) {
      return;
    }

    const { paymentFields, paymentType, scheduledMonths } = dataFields;

    let finalPaymentAmount: number | string = 0;

    paymentFields.forEach((item, key) => {
      const itemKeyName = paymentFieldRef.current[key];

      if (
        itemKeyName.toLowerCase().includes("cash") ||
        itemKeyName.toLowerCase().includes("amount")
      ) {
        finalPaymentAmount = item[itemKeyName] as string;
      }
    });

    const finalPaymentFields: PaymentFieldsBody[] = paymentFields.map(
      (item, key) => {
        const itemId = paymentFieldIdRef.current[key];
        const itemKeyName = paymentFieldRef.current[key];
        return {
          fieldId: itemId,
          value: itemKeyName.toLowerCase().includes("date")
            ? (moment(item[itemKeyName]).format("YYYY-MM-DD") as string)
            : (item[itemKeyName] as string),
        };
      }
    );

    addMutate({
      id,
      type: type === "unit" ? "UNIT" : "SERVICE",
      bodyData: {
        id,
        terms: scheduledMonths.value
          ? Number(scheduledMonths.value)
          : undefined,
        payment: {
          paymentId: Number(paymentType.value),
          amount: finalPaymentAmount,
          paymentFields: finalPaymentFields,
        },
      },
    });
  };

  const onError = () => {
    checkErrors();
  };

  if (paymentListError) {
    errorDisplayOrNavigate({
      error: paymentListError,
      toastId: "payment-list-config",
    });
  }

  if (receiptError) {
    errorDisplayOrNavigate({
      error: receiptError,
      toastId: "receipt",
    });
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-payment",
    });

    addReset();
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: "Successfully Paid!",
      toastId: "add-payment",
    });

    getReceiptTrigger({
      id: addData?.data.id as number,
      receiptType:
        receiptWatch.label === "Official Receipt"
          ? "OR"
          : receiptWatch.label === "Acknowledgement Receipt"
          ? "AR"
          : "PR",
      paymentType: type === "unit" ? "UNIT" : "SERVICE",
    });

    addReset();
  }

  useEffect(() => {
    if (paymentTypeWatch.value) {
      const selectedPayType = paymentListData?.data?.find(
        (item) => item.id === Number(paymentTypeWatch.value)
      );

      if (selectedPayType) {
        paymentFieldsReplace(
          selectedPayType.paymentFields.map(
            (item): { [name: string]: string } => ({
              [`${replaceAllSpaceWithHyphen(item.entries)}`]: "",
            })
          )
        );
        paymentFieldRef.current = selectedPayType.paymentFields.map(
          (item) => `${replaceAllSpaceWithHyphen(item.entries)}`
        );
        paymentFieldIdRef.current = selectedPayType.paymentFields.map(
          (item) => item.id
        );
      }
    }
  }, [paymentTypeWatch]);

  useEffect(() => {
    if (receiptIsSuccess) {
      handelToggleViewDoc(true, receiptData?.data.link ?? "");
    }
  }, [receiptIsSuccess]);

  useEffect(() => {
    if (detailsData?.data) {
      const findSched = scheduledMonthsOpt.find(
        (item) => item.label === detailsData.data.term
      );
      setValue("scheduledMonths", findSched ?? { label: "", value: null });
    }
  }, [detailsData]);

  return loading ? (
    <Stack justifyContent="center" alignItems="center">
      <CircularProgress
        size={30}
        sx={(theme) => ({
          color: theme.palette.primary.main,
        })}
      />
    </Stack>
  ) : (
    <>
      {viewDoc.open && (
        <FileViewModal
          open={viewDoc.open}
          path={viewDoc.path}
          title={viewDoc.path.substring(
            viewDoc.path.lastIndexOf("/") + 1,
            viewDoc.path.length
          )}
          onClose={() => handelToggleViewDoc(false, "")}
        />
      )}
      <FormProvider {...form}>
        <Stack spacing={2}>
          <div>
            <Typography variant="textfieldLabel">Payment Type</Typography>
            <CustomReactSelectJs
              control={control}
              options={paymentTypeOptions}
              name="paymentType"
              placeholder="Payment Type"
            />
          </div>
          {detailsData?.data.type !== "SCHEDULED" && (
            <div>
              <Typography variant="textfieldLabel">Scheduled</Typography>
              <CustomReactSelectJs
                control={control}
                options={scheduledMonthsOpt}
                name="scheduledMonths"
                placeholder="Months"
              />
            </div>
          )}
          {!_.isNull(paymentTypeWatch.value) && (
            <>
              {paymentFieldsWatch.map((item, key) => {
                const itemKeyName = Object.keys(item)[0];

                const itemLabelName = replaceAllHyphenWithSpace(itemKeyName);

                return (
                  <div key={key}>
                    <Typography variant="textfieldLabel">
                      {itemLabelName}
                    </Typography>
                    <Controller
                      name={`paymentFields.${key}.${itemKeyName}`}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      control={control}
                      render={({ field, fieldState }) => {
                        if (itemKeyName.toLowerCase().includes("date")) {
                          return (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                {...field}
                                slotProps={{
                                  textField: {
                                    variant: "outlined",
                                    error: fieldState.error?.message
                                      ? true
                                      : false,
                                    helperText: fieldState.error?.message,
                                    fullWidth: true,
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          );
                        }
                        return (
                          <TextField
                            {...field}
                            placeholder={itemLabelName}
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error?.message}
                            sx={{
                              width: "100%",
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                );
              })}
            </>
          )}
          <div>
            <Typography variant="textfieldLabel">Receipt</Typography>
            <CustomReactSelectJs
              control={control}
              options={receiptOpt}
              name="receipt"
              placeholder="Receipt"
            />
          </div>
          <Stack direction="row" spacing={2} paddingTop={2}>
            <Button
              variant="button-primary"
              sx={{
                width: 150,
              }}
              startIcon={
                saveLoading && (
                  <CircularProgress
                    size={20}
                    sx={(theme) => ({
                      color: theme.palette.common.white,
                    })}
                  />
                )
              }
              onClick={handleSubmit(onSubmit, onError)}
            >
              Pay
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </>
  );
};

export default PaymentForm;
